import React, {useState, useEffect, useCallback} from 'react';
import {
    Paper,
    Card,
    CardContent,
    Typography,
    Button,
    Checkbox,
    Collapse,
    IconButton,
    Snackbar,
    Alert,
    CircularProgress,
    Box,
    Skeleton,
    Chip,
    Tooltip
} from '@mui/material';
import {
    KeyboardArrowDown as ChevronDownIcon,
    KeyboardArrowUp as ChevronUpIcon,
    Print as PrinterIcon,
    CheckCircle as CheckIcon,
    LocalPrintshop as BatchPrintIcon,
    Refresh as RefreshIcon
} from '@mui/icons-material';
import { printOrders } from './../printOrders';
import {withAuthenticator} from "@aws-amplify/ui-react";

const LoadingButton = ({ isLoading, children, onClick, ...props }) => {
    return (
        <Button
            {...props}
            onClick={onClick}
            disabled={isLoading}
            sx={{
                position: 'relative',
                overflow: 'hidden',
                ...props.sx
            }}
        >
            {isLoading ? (
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <CircularProgress size={16} color="inherit" />
                    מדפיס...
                </Box>
            ) : children}
            {isLoading && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        height: '2px',
                        bgcolor: 'primary.light',
                        animation: 'loading 2s infinite linear'
                    }}
                />
            )}
        </Button>
    );
};

const STORAGE_KEY = 'printed_orders_state';
const STORAGE_TIMESTAMP_KEY = 'printed_orders_timestamp';

const OrderSummary = () => {
    const [selectedMeal, setSelectedMeal] = useState(null);
    const [isOrderSelected, setIsOrderSelected] = useState({});
    const [snackbarState, setSnackbarState] = useState({ open: false, message: '', severity: 'success' });
    const [printedMeals, setPrintedMeals] = useState([]);
    const [orders, setOrders] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(null);
    const [printingStates, setPrintingStates] = useState({});
    const [selectedOrders, setSelectedOrders] = useState(new Set());
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [lastRefreshed, setLastRefreshed] = useState(new Date());

    const [printedOrders, setPrintedOrders] = useState(() => {
        try {
            // Check if we have stored data and if it's from today
            const storedTimestamp = localStorage.getItem(STORAGE_TIMESTAMP_KEY);
            const isToday = storedTimestamp && new Date(storedTimestamp).toDateString() === new Date().toDateString();

            if (isToday) {
                const stored = localStorage.getItem(STORAGE_KEY);
                return new Set(JSON.parse(stored));
            }

            // If not today's data, clear storage and return empty set
            localStorage.removeItem(STORAGE_KEY);
            localStorage.removeItem(STORAGE_TIMESTAMP_KEY);
            return new Set();
        } catch (error) {
            console.error('Error loading printed orders from storage:', error);
            return new Set();
        }
    });

    useEffect(() => {
        try {
            localStorage.setItem(STORAGE_KEY, JSON.stringify(Array.from(printedOrders)));
            localStorage.setItem(STORAGE_TIMESTAMP_KEY, new Date().toISOString());
        } catch (error) {
            console.error('Error saving printed orders to storage:', error);
        }
    }, [printedOrders]);

    const updatePrintedOrders = useCallback((orderNumbers) => {
        setPrintedOrders(prev => {
            const newSet = new Set(prev);
            orderNumbers.forEach(orderNumber => newSet.add(orderNumber));
            return newSet;
        });
    }, []);


    useEffect(() => {
        fetchOrders();
    }, []);

    useEffect(() => {
        const storedTimestamp = localStorage.getItem(STORAGE_TIMESTAMP_KEY);
        if (storedTimestamp) {
            const storedDate = new Date(storedTimestamp).toDateString();
            const today = new Date().toDateString();

            if (storedDate !== today) {
                setPrintedOrders(new Set());
                localStorage.removeItem(STORAGE_KEY);
                localStorage.removeItem(STORAGE_TIMESTAMP_KEY);
            }
        }
    }, []);


    const handlePrintSelected = async () => {
        const selectedPrintJobs = Array.from(selectedOrders).flatMap(orderNumber => {
            const order = orders.find(o => o.order_number === orderNumber);
            return order.meals.map(meal => ({
                number: order.order_number,
                phone: order.phone_num,
                name: meal.name,
                items: [{
                    name: meal.meal,
                    quantity: 1,
                    price: meal.price || 0
                }]
            }));
        });

        setPrintingStates(prev => ({ ...prev, 'selected': true }));

        try {
            await printOrders(selectedPrintJobs);
            updatePrintedOrders(Array.from(selectedOrders));
            setSelectedOrders(new Set());
            showNotification('Selected orders printed successfully');
        } catch (error) {
            showNotification(`Error printing selected orders: ${error.message}`, 'error');
        } finally {
            setPrintingStates(prev => ({ ...prev, 'selected': false }));
        }
    };




    const fetchOrders = useCallback(async (showLoadingState = true) => {
        if (showLoadingState) {
            setIsLoading(true);
        } else {
            setIsRefreshing(true);
        }

        try {
            const response = await fetch(
                `https://utmwe6pof6.execute-api.us-east-1.amazonaws.com/getAllFromDB?table_name=my_orders&filter_today=true`,
                { headers: { 'Content-Type': 'application/json' } }
            );
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            const data = await response.json();
            setOrders(Array.isArray(data) ? data : []);
            setLastRefreshed(new Date());
        } catch (e) {
            setError(`Failed to load orders: ${e.message}`);
            showNotification(`Failed to refresh orders: ${e.message}`, 'error');
        } finally {
            if (showLoadingState) {
                setIsLoading(false);
            } else {
                setIsRefreshing(false);
            }
        }
    }, []);

    useEffect(() => {
        fetchOrders(true);
    }, [fetchOrders]);

    useEffect(() => {
        const intervalId = setInterval(() => {
            fetchOrders(false);
        }, 60000);

        return () => clearInterval(intervalId);
    }, [fetchOrders]);

    const handleManualRefresh = () => {
        fetchOrders(false);
    };

    const formatLastRefreshed = () => {
        const now = new Date();
        const diff = now - lastRefreshed;
        const minutes = Math.floor(diff / 60000);

        if (minutes < 1) return 'Just now';
        if (minutes === 1) return '1 minute ago';
        return `${minutes} minutes ago`;
    };

    const showNotification = (message, severity = 'success') => {
        setSnackbarState({
            open: true,
            message,
            severity
        });
    };

    const handlePrint = async (printJobs, context = 'all') => {
        const printId = context === 'all' ? 'all' :
            context === 'order' ? printJobs[0].number :
                `${printJobs[0].number}-${printJobs[0].items[0].name}`;

        setPrintingStates(prev => ({ ...prev, [printId]: true }));

        try {
            await printOrders(printJobs);
            setPrintedMeals(prev => [
                ...prev,
                ...printJobs.map(job => ({
                    name: job.name,
                    meal: job.items[0].name,
                    orderNumber: job.number
                }))
            ]);
            showNotification('התוויות נוצרו בהצלחה');
        } catch (error) {
            showNotification(`Error printing labels: ${error.message}`, 'error');
        } finally {
            setPrintingStates(prev => ({ ...prev, [printId]: false }));
        }
    };

    const handlePrintAll = async () => {
        setPrintingStates(prev => ({ ...prev, 'all': true }));

        try {
            const allPrintJobs = orders.flatMap(order =>
                order.meals.map(meal => ({
                    number: order.order_number,
                    phone: order.phone_num,
                    name: meal.name,
                    items: [{
                        name: meal.meal,
                        quantity: 1,
                        price: meal.price || 0
                    }]
                }))
            );

            await printOrders(allPrintJobs);
            updatePrintedOrders(orders.map(order => order.order_number));
            showNotification('All orders printed successfully');
        } catch (error) {
            showNotification(`Error printing orders: ${error.message}`, 'error');
        } finally {
            setPrintingStates(prev => ({ ...prev, 'all': false }));
        }
    };

    const handlePrintOrder = async (order) => {
        const printJobs = order.meals.map(meal => ({
            number: order.order_number,
            phone: order.phone_num,
            name: meal.name,
            items: [{
                name: meal.meal,
                quantity: 1,
                price: meal.price || 0
            }]
        }));

        setPrintingStates(prev => ({ ...prev, [order.order_number]: true }));

        try {
            await printOrders(printJobs);
            updatePrintedOrders([order.order_number]);
            showNotification('Order printed successfully');
        } catch (error) {
            showNotification(`Error printing order: ${error.message}`, 'error');
        } finally {
            setPrintingStates(prev => ({ ...prev, [order.order_number]: false }));
        }
    };

    const handleSelectOrder = (orderNumber) => {
        setSelectedOrders(prev => {
            const newSet = new Set(prev);
            if (newSet.has(orderNumber)) {
                newSet.delete(orderNumber);
            } else {
                newSet.add(orderNumber);
            }
            return newSet;
        });
    };

    // Handle select/deselect all orders
    const handleSelectAll = () => {
        if (selectedOrders.size === orders.length) {
            setSelectedOrders(new Set());
        } else {
            setSelectedOrders(new Set(orders.map(order => order.order_number)));
        }
    };


    const handlePrintMeal = (order, meal) => {
        const printJob = [{
            number: order.order_number,
            phone: order.phone_num,
            name: meal.name,
            items: [{
                name: meal.meal,
                quantity: 1,
                price: meal.price || 0
            }]
        }];
        handlePrint(printJob, 'meal');
    };

    const OrderRow = ({ order }) => {
        const [isOpen, setIsOpen] = useState(false);
        const isPrinting = printingStates[order.order_number] || false;
        const isSelected = selectedOrders.has(order.order_number);
        const isPrinted = printedOrders.has(order.order_number);

        return (
            <Card sx={{ mb: 2 }}>
                <CardContent sx={{ '&:last-child': { pb: 2 } }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: isOpen ? 2 : 0 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Checkbox
                                checked={isSelected}
                                onChange={() => handleSelectOrder(order.order_number)}
                            />
                            <Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Chip
                                        label={order.order_number}
                                        variant="outlined"
                                        size="small"
                                        icon={isPrinted ? <CheckIcon /> : undefined}
                                        color={isPrinted ? "success" : "default"}
                                    />
                                    <Typography variant="subtitle1" component="span">
                                        {order.owner_name}
                                    </Typography>
                                </Box>
                                <Typography variant="body2" color="text.secondary">
                                    {order.phone_num}
                                </Typography>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="body2" color="text.secondary">
                                {new Date(order.lut).toLocaleString('he-IL', { timeZone: 'Asia/Jerusalem' })}
                            </Typography>
                            <LoadingButton
                                variant="contained"
                                size="small"
                                onClick={() => handlePrintOrder(order)}
                                isLoading={isPrinting}
                                startIcon={<PrinterIcon />}
                            >
                                הדפס הכל
                            </LoadingButton>
                            <IconButton size="small" onClick={() => setIsOpen(!isOpen)}>
                                {isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
                            </IconButton>
                        </Box>
                    </Box>
                    <Collapse in={isOpen}>
                        <Box sx={{ mt: 2 }}>
                            <Paper variant="outlined">
                                {order.meals.map((meal, index) => {
                                    const mealId = `${order.order_number}-${meal.name}`;
                                    const isMealPrinting = printingStates[mealId] || false;
                                    const isMealPrinted = printedMeals.some(m =>
                                        m.name === meal.name && m.orderNumber === order.order_number
                                    );

                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                p: 2,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                borderBottom: index < order.meals.length - 1 ? 1 : 0,
                                                borderColor: 'divider'
                                            }}
                                        >
                                            <Box>
                                                <Typography variant="subtitle2">{meal.name}</Typography>
                                                <Typography variant="body2" color="text.secondary">
                                                    {meal.meal}
                                                </Typography>
                                            </Box>
                                            <LoadingButton
                                                variant="outlined"
                                                size="small"
                                                onClick={() => handlePrintMeal(order, meal)}
                                                isLoading={isMealPrinting}
                                                startIcon={isMealPrinted ? <CheckIcon color="success" /> : null}
                                            >
                                                Print Meal
                                            </LoadingButton>
                                        </Box>
                                    );
                                })}
                            </Paper>
                        </Box>
                    </Collapse>
                </CardContent>
            </Card>
        );
    };

    if (isLoading) {
        return (
            <Box sx={{ mt: 2 }}>
                {[1, 2, 3].map((i) => (
                    <Card key={i} sx={{ mb: 2 }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Skeleton variant="circular" width={24} height={24} />
                                <Box sx={{ flex: 1 }}>
                                    <Skeleton variant="text" width={200} />
                                    <Skeleton variant="text" width={150} />
                                </Box>
                            </Box>
                        </CardContent>
                    </Card>
                ))}
            </Box>
        );
    }

    if (error) {
        return (
            <Alert severity="error" sx={{ mt: 2 }}>
                {error}
            </Alert>
        );
    }

    if (orders.length === 0) {
        return (
            <Alert severity="info" sx={{ mt: 2 }}>
                אין הזמנות כרגע.
            </Alert>
        );
    }

    return (
        <Box sx={{mt: 2}}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2}}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: 2}}>
                    <Typography variant="h5">Orders Summary</Typography>
                    <Checkbox
                        checked={selectedOrders.size === orders.length}
                        indeterminate={selectedOrders.size > 0 && selectedOrders.size < orders.length}
                        onChange={handleSelectAll}
                    />
                    <Typography variant="body2" color="text.secondary">
                        {selectedOrders.size} orders selected
                    </Typography>
                    <Tooltip title={`Last refreshed: ${formatLastRefreshed()}`}>
                        <Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
                            <IconButton
                                onClick={handleManualRefresh}
                                disabled={isRefreshing}
                                size="small"
                                color="primary"
                            >
                                <RefreshIcon
                                    sx={{
                                        animation: isRefreshing ? 'spin 1s linear infinite' : 'none'
                                    }}
                                />
                            </IconButton>
                            <Typography variant="caption" color="text.secondary">
                                {isRefreshing ? 'Refreshing...' : formatLastRefreshed()}
                            </Typography>
                        </Box>
                    </Tooltip>

                </Box>
                <Box sx={{display: 'flex', gap: 2}}>
                    {selectedOrders.size > 0 && (
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick={handlePrintSelected}
                            isLoading={printingStates.selected}
                            startIcon={<BatchPrintIcon/>}
                        >
                            הדפס נבחרים ({selectedOrders.size})
                        </LoadingButton>
                    )}
                    <LoadingButton
                        variant="contained"
                        onClick={handlePrintAll}
                        isLoading={printingStates.all}
                        startIcon={<PrinterIcon/>}
                    >
                        הדפס את כל ההזמנות
                    </LoadingButton>
                </Box>
            </Box>

            {orders.map((order) => (
                <OrderRow key={order.order_number} order={order}/>
            ))}

            <Snackbar
                open={snackbarState.open}
                autoHideDuration={3000}
                onClose={() => setSnackbarState(prev => ({...prev, open: false}))}
            >
                <Alert
                    onClose={() => setSnackbarState(prev => ({...prev, open: false}))}
                    severity={snackbarState.severity}
                    sx={{width: '100%'}}
                >
                    {snackbarState.message}
                </Alert>
            </Snackbar>

            <style>
                {`
          @keyframes loading {
            0% { transform: translateX(-100%); }
            100% { transform: translateX(100%); }
          }
          @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
        `}
            </style>
        </Box>
    );
};

export default OrderSummary;