import React, {useEffect, useState} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './components/Login';
import Order from './components/newOrderForm';
import OrderSummary from './components/OrdersSummery';
import MenuEditComponent from './components/MenuEditComponent';
import ViewOrder from './components/OrderView';
import menuData from './menuData.json';
import MgrLoginComponent from './components/ManagerLoginScreen'
import ManagementDashboard from './components/ManagmentDashboard'
import { Amplify } from 'aws-amplify';
import amplifyconfiguration from './amplifyconfiguration';
import { colors } from './colors';
import ExistingOrder from "./components/ExistingOrders";
import { Phone, Mail, MapPin, X } from 'lucide-react';
import NotFound from "./components/NotFound";
import {fetchTodayHours} from "./api/shopHoursApi";
import ContactInfo from "./components/ContactInfo";
import MenuEditor from "./components/MenuEditor";

Amplify.configure(amplifyconfiguration);

function App() {
    const [showContactPopup, setShowContactPopup] = useState(false);
    const [isShopOpen, setIsShopOpen] = useState(true);
    const [shopHours, setShopHours] = useState(null);
    const [isLoadingShopStatus, setIsLoadingShopStatus] = useState(true);
    const [vh, setVh] = useState(window.innerHeight * 0.01);

    const toggleContactPopup = () => {
        setShowContactPopup(!showContactPopup);
    };

    useEffect(() => {
        const handleResize = () => {
            setVh(window.innerHeight * 0.01);
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };

        handleResize();

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [vh]);

    useEffect(() => {
        const checkShopStatus = async () => {
            setIsLoadingShopStatus(true);
            try {
                const hours = await fetchTodayHours();
                const now = new Date();
                const currentTime = now.getHours() * 60 + now.getMinutes();
                const [startHour, startMinute] = hours.start_time.split(':').map(Number);
                const [endHour, endMinute] = hours.end_time.split(':').map(Number);
                const startTime = startHour * 60 + startMinute;
                const endTime = endHour * 60 + endMinute;

                setShopHours(hours);
                setIsShopOpen(currentTime >= startTime && currentTime <= endTime);
            } catch (error) {
                console.error('Error checking shop status:', error);
                setIsShopOpen(false);
            } finally {
                setIsLoadingShopStatus(false);
            }
        };

        checkShopStatus();
    }, []);




    // const [vh, setVh] = useState(window.innerHeight * 0.01);
    //
    // useEffect(() => {
    //     const handleResize = () => {
    //         setVh(window.innerHeight * 0.01);
    //         document.documentElement.style.setProperty('--vh', `${vh}px`);
    //     };
    //
    //     handleResize();
    //
    //     window.addEventListener('resize', handleResize);
    //     return () => {
    //         window.removeEventListener('resize', handleResize);
    //     };
    // }, [vh]);

    return (
        <BrowserRouter>
            <Layout
                toggleContactPopup={toggleContactPopup}
                isShopOpen={isShopOpen}
            >
                <Routes>
                    <Route path="/" element={<Login
                        toggleContactPopup={toggleContactPopup}
                        isShopOpen={isShopOpen}
                        shopHours={shopHours}
                        isLoadingShopStatus={isLoadingShopStatus}
                    />} />
                    <Route path="/order" element={<Order />} />
                    <Route path="/summary" element={<OrderSummary />} />
                    <Route path="/mgr-dashboard" element={<ManagementDashboard />} />
                    <Route path="/login-mgr" element={<MgrLoginComponent />} />
                    <Route path="/edit-menu" element={<MenuEditComponent initialMenuData={menuData} />} />
                    <Route path="/existing-order" element={<ExistingOrder/>} />
                    <Route path="/view-order" element={<ViewOrder />} />
                    <Route path="/menu-editor" element={<MenuEditor />} />

                    {/* Add the catch-all route at the end */}
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </Layout>
            {showContactPopup && (
                <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50 backdrop-blur-sm">
                    <ContactInfo onClose={toggleContactPopup} />
                </div>
            )}
        </BrowserRouter>
    );
}

export default App;