import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { Card, CardContent, CardHeader } from './ui/card';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Plus, Trash2, GripVertical, Settings } from 'lucide-react';
import CategorySettingsDialog from './CategorySettingsDialog';

const MenuEditor = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);

    useEffect(() => {
        fetchMenu();
    }, []);

    const fetchMenu = async () => {
        try {
            const response = await fetch(
                'https://utmwe6pof6.execute-api.us-east-1.amazonaws.com/menu-operations?' +
                new URLSearchParams({
                    operation: 'get_full_menu'
                }), {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Accept': 'application/json'
                    }
                });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            const parsedData = typeof data.body === 'string' ? JSON.parse(data.body) : data;
            const categoriesArray = Array.isArray(parsedData) ? parsedData : [];
            setCategories(categoriesArray);
            setError(null);
        } catch (error) {
            console.error('Error fetching menu:', error);
            setError('Failed to load menu data');
            setCategories([]);
        } finally {
            setLoading(false);
        }
    };

    const handleDragEnd = (result) => {
        if (!result.destination) return;

        const items = Array.from(categories);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        // Update indices
        const updatedItems = items.map((item, index) => ({
            ...item,
            display_order: index
        }));

        setCategories(updatedItems);
        // Save new order to database
        saveCategories(updatedItems);
    };

    const addCategory = async () => {
        const newCategory = {
            id: `cat-${Date.now()}`,
            name: 'New Category',
            display_name: 'New Category',
            display_order: categories.length,
            customizations: [],
            items: [],
            allows_drinks: false,
            allows_sides: false,
            max_sides: 0,
            mandatory_sides: 0
        };

        try {
            const response = await fetch('https://utmwe6pof6.execute-api.us-east-1.amazonaws.com/menu-operations', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    operation: 'add_category',
                    data: newCategory
                })
            });
            if (!response.ok) {
                throw new Error('Failed to add category');
            }
            setCategories([...categories, newCategory]);
        } catch (error) {
            console.error('Error adding category:', error);
            setError('Failed to add category');
        }
    };

    const editCategory = async (id, field, value) => {
        const updatedCategories = categories.map(cat => {
            if (cat.id === id) {
                return { ...cat, [field]: value };
            }
            return cat;
        });

        setCategories(updatedCategories);
        const category = updatedCategories.find(cat => cat.id === id);

        try {
            const response = await fetch('https://utmwe6pof6.execute-api.us-east-1.amazonaws.com/menu-operations', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    operation: 'update_category',
                    data: category
                })
            });
            if (!response.ok) {
                throw new Error('Failed to update category');
            }
        } catch (error) {
            console.error('Error updating category:', error);
            setError('Failed to update category');
        }
    };

    const handleDeleteCategory = async (id) => {
        try {
            const response = await fetch('https://utmwe6pof6.execute-api.us-east-1.amazonaws.com/menu-operations', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    operation: 'delete_category',
                    data: { id }
                })
            });
            if (!response.ok) {
                throw new Error('Failed to delete category');
            }
            setCategories(categories.filter(cat => cat.id !== id));
        } catch (error) {
            console.error('Error deleting category:', error);
            setError('Failed to delete category');
        }
    };

    const handleSaveCategory = async (updatedCategory) => {
        try {
            const response = await fetch('https://utmwe6pof6.execute-api.us-east-1.amazonaws.com/menu-operations', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    operation: 'update_category',
                    data: updatedCategory
                })
            });
            if (!response.ok) {
                throw new Error('Failed to save category');
            }
            setCategories(categories.map(cat =>
                cat.id === updatedCategory.id ? updatedCategory : cat
            ));
        } catch (error) {
            console.error('Error saving category:', error);
            setError('Failed to save category');
        }
    };

    const saveCategories = async (updatedCategories) => {
        try {
            for (const category of updatedCategories) {
                const response = await fetch('https://utmwe6pof6.execute-api.us-east-1.amazonaws.com/menu-operations', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        operation: 'update_category',
                        data: category
                    })
                });
                if (!response.ok) {
                    throw new Error('Failed to save categories');
                }
            }
        } catch (error) {
            console.error('Error saving categories:', error);
            setError('Failed to save categories');
        }
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center min-h-screen">
                <div className="text-red-500 mb-4">{error}</div>
                <button
                    onClick={fetchMenu}
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                >
                    Retry
                </button>
            </div>
        );
    }

    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Menu Editor</h1>
                <Button onClick={addCategory} className="flex items-center gap-2">
                    <Plus size={16} />
                    Add Category
                </Button>
            </div>

            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="categories">
                    {(provided) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {categories.map((category, index) => (
                                <Draggable
                                    key={category.id}
                                    draggableId={category.id}
                                    index={index}
                                >
                                    {(provided) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            className="mb-4"
                                        >
                                            <Card>
                                                <CardHeader className="flex flex-row items-center justify-between p-4">
                                                    <div {...provided.dragHandleProps} className="mr-2">
                                                        <GripVertical className="text-gray-400" />
                                                    </div>
                                                    <Input
                                                        value={category.display_name}
                                                        onChange={(e) => editCategory(category.id, 'display_name', e.target.value)}
                                                        className="max-w-sm"
                                                    />
                                                    <div className="flex items-center gap-2">
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            onClick={() => setSelectedCategory(category)}
                                                        >
                                                            <Settings size={16} />
                                                        </Button>
                                                        <Button
                                                            variant="ghost"
                                                            size="sm"
                                                            className="text-red-500"
                                                            onClick={() => handleDeleteCategory(category.id)}
                                                        >
                                                            <Trash2 size={16} />
                                                        </Button>
                                                    </div>
                                                </CardHeader>
                                            </Card>
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            {selectedCategory && (
                <CategorySettingsDialog
                    category={selectedCategory}
                    onClose={() => setSelectedCategory(null)}
                    onSave={handleSaveCategory}
                />
            )}
        </div>
    );
};

export default MenuEditor;