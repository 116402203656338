import React, { useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from './ui/dialog';
import { Button } from './ui/button';
import { Input } from './ui/input';
import { Checkbox } from './ui/checkbox';
import { Label } from './ui/label';

const CategorySettingsDialog = ({ category, onClose, onSave }) => {
    const [settings, setSettings] = useState({
        allows_drinks: category.allows_drinks || false,
        allows_sides: category.allows_sides || false,
        max_sides: category.max_sides || 0,
        mandatory_sides: category.mandatory_sides || 0,
        customization_groups: category.customization_groups || []
    });

    const handleSave = () => {
        onSave({ ...category, ...settings });
        onClose();
    };

    return (
        <Dialog open={true} onOpenChange={onClose}>
            <DialogContent>
                <DialogHeader>
                    <DialogTitle>Category Settings - {category.display_name}</DialogTitle>
                </DialogHeader>

                <div className="space-y-4 py-4">
                    <div className="flex items-center space-x-2">
                        <Checkbox
                            id="allows_drinks"
                            checked={settings.allows_drinks}
                            onCheckedChange={(checked) =>
                                setSettings(prev => ({ ...prev, allows_drinks: checked }))
                            }
                        />
                        <Label htmlFor="allows_drinks">Allow Drinks Selection</Label>
                    </div>

                    <div className="flex items-center space-x-2">
                        <Checkbox
                            id="allows_sides"
                            checked={settings.allows_sides}
                            onCheckedChange={(checked) =>
                                setSettings(prev => ({ ...prev, allows_sides: checked }))
                            }
                        />
                        <Label htmlFor="allows_sides">Allow Sides Selection</Label>
                    </div>

                    {settings.allows_sides && (
                        <div className="space-y-2">
                            <div>
                                <Label htmlFor="max_sides">Maximum Sides</Label>
                                <Input
                                    id="max_sides"
                                    type="number"
                                    value={settings.max_sides}
                                    onChange={(e) => setSettings(prev => ({
                                        ...prev,
                                        max_sides: parseInt(e.target.value)
                                    }))}
                                    min="0"
                                />
                            </div>
                            <div>
                                <Label htmlFor="mandatory_sides">Mandatory Sides</Label>
                                <Input
                                    id="mandatory_sides"
                                    type="number"
                                    value={settings.mandatory_sides}
                                    onChange={(e) => setSettings(prev => ({
                                        ...prev,
                                        mandatory_sides: parseInt(e.target.value)
                                    }))}
                                    min="0"
                                    max={settings.max_sides}
                                />
                            </div>
                        </div>
                    )}
                </div>

                <DialogFooter>
                    <Button variant="outline" onClick={onClose}>Cancel</Button>
                    <Button onClick={handleSave}>Save Changes</Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default CategorySettingsDialog;